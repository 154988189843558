<template>
  <div class="py-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
    <h1>No start, no end, just a block that needs to be finished.</h1>
  </div>
  <main>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      Async world
    </div>
  </main>
</template>
